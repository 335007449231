export const BAKER_CONFIG = {
	CLIENT_ID: process.env.REACT_APP_BAKER_CLIENT_ID,
	CLIENT_SECRET: process.env.REACT_APP_BAKER_CLIENT_SECRET,
	API_URL: 'https://openapi.data-baker.com'
  };
  
  export const ZHIPU_CONFIG = {
	API_URL: 'https://open.bigmodel.cn/api/paas/v4/'
  };

  export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://0.0.0.0:3001';