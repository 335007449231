import { ZHIPU_CONFIG } from '../config/constants';

class ZhipuService {
  constructor() {
    this.maxAttempts = 60;
    this.checkInterval = 5000;
  }

  async generateVideo(prompt, apiKey) {
    try {
      const response = await fetch(`${ZHIPU_CONFIG.API_URL}/videos/generations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({ 
          prompt, 
          model: 'cogvideox',
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error generating video:', error);
      throw new Error(`Failed to generate video: ${error.message}`);
    }
  }

  async checkVideoStatus(id, onProgress, apiKey) {
    let attempts = 0;

    while (attempts < this.maxAttempts) {
      try {
        const response = await fetch(`${ZHIPU_CONFIG.API_URL}/async-result/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const statusResult = await response.json();
        const progress = Math.min(90, (attempts / this.maxAttempts) * 100);
        onProgress && onProgress(progress, 'Video is processing, please wait...');

        if (statusResult.task_status === 'SUCCESS') {
          onProgress && onProgress(100, 'Video generation completed!');
          return {
            status: 'success',
            result: statusResult.video_result[0]
          };
        } else if (statusResult.task_status === 'FAILED') {
          throw new Error(statusResult.message || 'Video generation failed');
        }

        await new Promise(resolve => setTimeout(resolve, this.checkInterval));
        attempts++;
      } catch (error) {
        console.error('Error checking video status:', error);
        throw new Error(`Failed to check video status: ${error.message}`);
      }
    }

    throw new Error('Video generation timed out');
  }

  async generateAndWaitForVideo(prompt, onProgress, apiKey) {
    try {
      onProgress && onProgress(0, 'Starting video generation...');
      const generateResult = await this.generateVideo(prompt, apiKey);
      
      if (!generateResult.id) {
        throw new Error('No video ID received');
      }

    //   const result = await this.checkVideoStatus(generateResult.id, onProgress, apiKey);
	  const result = {
		"status": "success",
		"result": {
			"cover_image_url": "https://aigc-files.bigmodel.cn/api/cogvideo/00077af6-9fce-11ef-885a-5e01a0c308a2_cover_0.jpeg",
			"url": "https://aigc-files.bigmodel.cn/api/cogvideo/00077af6-9fce-11ef-885a-5e01a0c308a2_0.mp4"
		}
	}
	  console.log(result);
      return result;
    } catch (error) {
      console.error('Error in video generation process:', error);
      throw error;
    }
  }
}

export const zhipuService = new ZhipuService();