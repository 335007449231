import { styled } from '@mui/material/styles';
import { Button, Card, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const TopButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  right: 20,
  borderRadius: 12,
  padding: '8px 16px',
  backgroundColor: '#2A3441',
  color: theme.palette.text.secondary,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: alpha('#2A3441', 0.8),
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.15)',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '8px 24px',
  fontSize: '16px',
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  '&.primary': {
    backgroundColor: theme.palette.primary.main,
    color: '#000',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
}));

export const StyledInputArea = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  borderRadius: 16,
  padding: '20px',
  marginBottom: '20px',
  minHeight: '150px',
}));

export const GenerateButton = styled(Button)(({ theme }) => ({
  padding: '16px',
  borderRadius: 12,
  backgroundColor: theme.palette.primary.main,
  color: '#000',
  fontSize: '18px',
  marginBottom: '16px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  '&:disabled': {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

export const VideoContainer = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

export const ContentWrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const ButtonGroup = styled(Box)({
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
  marginBottom: '24px',
});

export const StatusText = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginTop: '8px',
}));