import React from 'react';
import { TopButton } from '../styles/styledComponents';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from 'react-router-dom';  // 添加这行


export const TopButtons = () => {
  const navigate = useNavigate();  // 添加这行

  const handleAdminClick = () => {
    window.open('https://open.bigmodel.cn/finance/overview', '_blank');
  };

  return (
    <>
      <TopButton
        onClick={handleAdminClick}
        sx={{ top: 20 }}
      >
        
      </TopButton>

      <TopButton
        onClick={() => navigate('/history')}  // 修改这行
        sx={{ top: 80 }}
      >
      </TopButton>
    </>
  );
};