import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  Button,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Select,
  Pagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/constants';
import '../styles/history.css'
import dayjs from 'dayjs'

export const History = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const now = dayjs();
  
 


  // 分页相关状态
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 6; // 每页显示的视频数量

  // 处理分页变化
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // 获取当前页的视频
  const getCurrentPageVideos = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return videos.slice(startIndex, endIndex);
  };

  // 计算总页数
  useEffect(() => {
    setTotalPages(Math.ceil(videos.length / itemsPerPage));
  }, [videos]);

  // 时间选择相关
  const nextThreeMonths = [now.format('YYYY-MM')];
  for (let i = 0; i < 3; i++) {
    nextThreeMonths.push(now.add(i + 1, 'month').format('YYYY-MM'));
  }
  const options = [...nextThreeMonths];
  const [selectedValue, setSelectedValue] = useState(options[0]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setPage(1); // 重置页码到第一页
    loadSavedPrompts(); // 重新加载数据
  };

  const loadSavedPrompts = async () => {
    try {
      setLoading(true);
      setError(null);

      const userPhone = JSON.parse(localStorage.getItem('userPhone'));
      console.log('当前用户手机号:', userPhone);
      if (!userPhone) {
        throw new Error('用户未登录');
      }
      // setUserPhone(userPhone);
      
      const response = await fetch(`${API_BASE_URL}/api/videos/${userPhone}?time=${selectedValue}`);
      if (!response.ok) {
        throw new Error('获取历史记录失败');
      }
      
      const data = await response.json();
      console.log('获取到的数据:', data);
      
      const videoList = Array.isArray(data.videos) ? data.videos : [];
      
      const formattedVideos = videoList
        .filter(video => video && video.url)
        .map(video => ({
          id: video.id,
          name: video.name || `Video ${video.id}`,
          url: video.url,
          created: video.created || new Date().toISOString(),
          prompt: video.prompt
        }));
      
      setVideos(formattedVideos);
      setPage(1); // 重置页码
    } catch (err) {
      console.error('加载历史记录失败:', err);
      setError(err.message || '加载历史记录失败');
      setVideos([]);
    } finally {
      setLoading(false);
      // const testdata = {
      //   "id": "1731289642622",
      //   "prompt": "可爱小狗在做饭。",
      //   "videoUrl": "https://aigc-files.bigmodel.cn/api/cogvideo/00077af6-9fce-11ef-885a-5e01a0c308a2_0.mp4",
      //   "created": "2024-11-11T01:47:22.622Z"
      // };
      // let arr = []
      // for(let i = 0;i<20;i++){
      //   arr.push(testdata)
      // }
      // setError(null);
      // setVideos(arr);

    }
  };
  const handleAdminClick = () => {
    window.open('https://open.bigmodel.cn/finance/overview', '_blank');
  };

  

  const handleDelete = async (videoId) => {
    if (!videoId || !window.confirm('确定要删除这个记录吗？')) {
      return;
    }

    try {
      setError(null);
      const response = await fetch(`${API_BASE_URL}/api/videos/${videoId}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete record');
      }

      await loadSavedPrompts(); // 重新加载列表
    } catch (err) {
      console.error('删除记录失败:', err);
      setError(err.message || '删除记录失败');
    }
  };

  const handleRefresh = async () => {
    if (refreshing) return;
    
    setRefreshing(true);
    try {
      await loadSavedPrompts();
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    loadSavedPrompts();
    
    return () => {
      setVideos([]);
      setLoading(false);
      setError(null);
    };
  }, [selectedValue]); // 添加 selectedValue 作为依赖

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        sx={{ bgcolor: '#0A0C10' }}
      >
        <CircularProgress sx={{ color: 'gainsboro' }} />
      </Box>
    );
  }

  return (
    <Box className='history-box' sx={{ 
      bgcolor: '#0A0C10',
      minHeight: '100vh',
      py: 4,
      position: 'relative'
    }}>
      <Box sx={{ 
      }}>
        <div className='left-top-logo'>
          <div className='img-box'></div>
          <div>
          <select className='select-box' value={selectedValue} onChange={handleChange} style={{width:'100%'}}>
          {options.map((option, index) => (
              <option className='option-box' key={index} value={option}>
                {option}
              </option>
          ))}
          </select>
          {/* <FormControl>
          <Select
  value={selectedValue}
  onChange={handleChange}
>
  {options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option}
    </MenuItem>
  ))}
</Select>
</FormControl> */}

          </div>
          <div className='managePlate-btn' onClick={handleAdminClick}></div>
          <div className='goback-btn' onClick={() => navigate('/')}></div>
        </div>
        <div>
        </div>
        
        {/* <Button
          startIcon={<HomeIcon />}
          onClick={() => navigate('/')}
          sx={{
            color: 'gainsboro',
            '&:hover': {
              bgcolor: '#cfcfcf'
            }
          }}
        >
          主页
        </Button> */}
      </Box>

      <Container maxWidth="lg" className='right-box'>
        {/* 标题和刷新按钮 */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4
        }}>
          <Typography 
            variant="h4" 
            sx={{ color: 'gainsboro' }}
          >
            历史记录
          </Typography>
          
          <Tooltip title="刷新列表">
            <IconButton 
              onClick={handleRefresh}
              disabled={refreshing}
              sx={{ 
                color: 'gainsboro',
                '&:hover': {
                  bgcolor: '#cfcfcf'
                }
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              bgcolor: 'rgba(255, 77, 79, 0.1)',
              color: '#ff4d4f',
              '& .MuiAlert-icon': {
                color: '#ff4d4f'
              }
            }}
          >
            {error}
          </Alert>
        )}

        {!loading && !error && videos.length === 0 && (
          <Typography 
            variant="body1" 
            sx={{ color: 'grey.300' }} 
            align="center"
          >
            暂无历史记录
          </Typography>
        )}

        {!loading && !error && videos.length > 0 && (
          <>
            <Box sx={{ minHeight: 'calc(100vh - 300px)' }}>
              <Grid container spacing={3}>
                {getCurrentPageVideos().map((video) => (
                  <Grid item xs={12} sm={6} md={4} key={video.id}>
                    <Card 
                      sx={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: 'rgba(30, 34, 42, 0.9)',
                        '&:hover': {
                          boxShadow: '0 0 10px rgba(220, 220, 220, 0.2)'
                        }
                      }}
                    >
                      <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                        <Box
                          component="video"
                          controls
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            bgcolor: 'black'
                          }}
                          src={video.url}
                          onError={(e) => {
                            console.error('视频加载错误:', e);
                            e.target.onerror = null;
                          }}
                        />
                      </Box>
                      
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography 
                          variant="h6" 
                          component="div" 
                          noWrap 
                          title={video.name}
                          sx={{ color: 'gainsboro' }}
                        >
                          {video.name}
                        </Typography>
                        {video.prompt && (
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              mt: 1,
                              color: 'grey.300',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            提示词: {video.prompt}
                          </Typography>
                        )}
                        <Typography 
                          variant="body2" 
                          sx={{ mt: 1, color: 'grey.300' }}
                        >
                          创建时间: {new Date(video.created).toLocaleString()}
                        </Typography>
                      </CardContent>

                      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                        <Button 
                          size="small" 
                          sx={{
                            color: '#ff4d4f',
                            '&:hover': {
                              bgcolor: 'rgba(255, 77, 79, 0.1)'
                            }
                          }}
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDelete(video.id)}
                        >
                          删除
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* 固定的分页控制器 */}
            <Box
            className='page-box'
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 2,
                bgcolor: '#fff',
                color:'#000',
                borderTop: '1px solid rgba(220, 220, 220, 0.1)',
                backdropFilter: 'blur(8px)',
                zIndex: 1000
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="info"
                size="large"
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: '#000',
                    bgcolor: '#f5f5f5',
                    '&.Mui-selected': {
                      bgcolor: '#222',
                      color:'#fff',
                    },
                    '&:hover': {
                      bgcolor: '#f5f5f5',
                      color:'#222'
                    },
                  },
                }}
              />
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default History;