import { BAKER_CONFIG } from '../config/constants';

class BakerService {
  async getAccessToken() {
    try {
      const url = `${BAKER_CONFIG.API_URL}/oauth/2.0/token?grant_type=client_credentials&client_secret=${BAKER_CONFIG.CLIENT_SECRET}&client_id=${BAKER_CONFIG.CLIENT_ID}`;
      
      const response = await fetch(url, {
        method: 'POST',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  }

  async recognizeAudio(audioBlob, accessToken) {
    try {
      const headers = {
        'access_token': accessToken,
        'audio_format': 'wav',
        'sample_rate': '16000',
        'add_pct': 'true'
      };

      const url = `${BAKER_CONFIG.API_URL}/asr/api`;
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: audioBlob
      });
	  console.log(audioBlob)

      const data = await response.json();
      
      if (data.code !== 20000) {
        throw new Error(data.message || '识别失败');
      }

      return data.text;
    } catch (error) {
      console.error('Error recognizing audio:', error);
      throw error;
    }
  }
}
export const bakerService = new BakerService();
export default BakerService;
