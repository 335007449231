import React from 'react';
import { Box } from '@mui/material';

export const VideoPlayer = ({ videoUrl }) => {
  if (!videoUrl) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'grey.500'
        }}
      >
        等待生成视频...
      </Box>
    );
  }

  return (
    <Box
      component="video"
      controls
      autoPlay
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }}
      src={videoUrl}
    />
  );
};