import React, { useState, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Button,
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  IconButton,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AudioRecorder } from '../components/AudioRecorder';
import { VideoPlayer } from '../components/VideoPlayer';
import { zhipuService } from '../services/zhipuService';
import { useAudioRecorder } from '../hooks/useAudioRecorder';
import LinearProgress from '@mui/material/LinearProgress';
import { API_BASE_URL } from '../config/constants';
import '../styles/home.css'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'
import { TopButtons } from '../components/TopButtons';

import 'bootstrap/dist/css/bootstrap.min.css';

import {  Modal } from 'react-bootstrap';

// 用户信息组件
const UserInfo = React.memo(() => (
  <Typography 
    variant="body1" 
    sx={{ 
      color: 'cyan',
      mr: 2
    }}
  >
    欢迎, {localStorage.getItem('userName')}
  </Typography>
));

// 添加用户对话框组件
const AddUserDialog = React.memo(({ 
  open, 
  onClose, 
  userData, 
  onUserDataChange, 
  onAddUser 
}) => (
  <Dialog open={open} onClose={onClose} className='user-dialog'>
    <DialogTitle>添加新用户</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="手机号"
        type="tel"
        fullWidth
        value={userData.phone}
        onChange={(e) => onUserDataChange({ ...userData, phone: e.target.value })}
      />
      <TextField
        margin="dense"
        label="密码"
        type="password"
        autoComplete="new-password"
        fullWidth
        value={userData.password}
        onChange={(e) => onUserDataChange({ ...userData, password: e.target.value })}
      />
      <TextField
        margin="dense"
        label="姓名"
        fullWidth
        value={userData.name}
        onChange={(e) => onUserDataChange({ ...userData, name: e.target.value })}
      />
      <TextField
        margin="dense"
        label="API Key"
        fullWidth
        value={userData.apiKey}
        onChange={(e) => onUserDataChange({ ...userData, apiKey: e.target.value })}
      />
      <TextField
        margin="dense"
        label="角色"
        select
        fullWidth
        value={userData.role}
        onChange={(e) => onUserDataChange({ ...userData, role: e.target.value })}
      >
        <MenuItem value="user">普通用户</MenuItem>
        <MenuItem value="admin">管理员</MenuItem>
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>取消</Button>
      <Button onClick={onAddUser} variant="contained">添加</Button>
    </DialogActions>
  </Dialog>
));

function Home() {
  // 状态管理
  const [status, setStatus] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [inputText, setInputText] = useState('');
  const [inputMode, setInputMode] = useState('voice');
  const time = dayjs().locale('zh-cn').format('YYYY.MM.DD dddd')
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    return localStorage.getItem('isAdmin') === 'true';
  });
  const [loginOpen, setLoginOpen] = useState(() => {
    return !localStorage.getItem('isLoggedIn');
  });
  const [showAddUser, setShowAddUser] = useState(false);
  const [loginData, setLoginData] = useState({
    phone: '',
    password: ''
  });
  const [userApiKey, setUserApiKey] = useState(() => {
    return localStorage.getItem('userApiKey') || '';
  });
  const [userPhone, setUserPhone] = useState(() => {
    return localStorage.getItem('userPhone') || '';
  });
  const [newUser, setNewUser] = useState({
    phone: '',
    password: '',
    name: '',
    apiKey: '',
    role: 'user'
  });
  const zoomValue = window.innerWidth/3840
  
  // 音频录制相关功能
  const {
    isRecording,
    transcript,
    startRecording,
    stopRecording,
    setTranscript
  } = useAudioRecorder();

  // 处理文本输入变化
  const handleTextChange = useCallback((newText) => {
    if (inputMode === 'voice') {
      setTranscript(newText);
    } else {
      setInputText(newText);
    }
  }, [inputMode, setTranscript]);

  // 切换输入模式
  const handleModeChange = useCallback((mode) => {
    setInputMode(mode);
    if (mode === 'text') {
      setTranscript('');
    } else {
      setInputText('');
    }
  }, [setTranscript]);

  // 重置所有状态
  const handleReset = useCallback(() => {
    setVideoUrl('');
    setStatus('');
    setProgress(0);
    setInputText('');
    setTranscript('');
    setInputMode('voice');
  }, [setTranscript]);

  // 处理登录
  const handleLogin = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/videos/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData)
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('isAdmin', data.user.role === 'admin');
        localStorage.setItem('userApiKey', data.user.apiKey);
        localStorage.setItem('userName', data.user.name);
		localStorage.setItem('userPhone', data.user.phone);

        setIsLoggedIn(true);
        setLoginOpen(false);
        setUserApiKey(data.user.apiKey);
        setIsAdmin(data.user.role === 'admin');
		setUserPhone(data.user.phone)
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('登录失败，请重试');
    }
  };

  const [registerOpen, setRegisterOpen] = useState(false);
  const register = ()=>{
    setRegisterOpen(true)
  }

  // 处理退出登录
  const handleLogout = async () => {
    try {
      await fetch(`${API_BASE_URL}/api/videos/logout`, {
        method: 'POST',
        credentials: 'include'
      });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('userApiKey');
      localStorage.removeItem('userName');

      setIsLoggedIn(false);
      setLoginOpen(true);
      setIsAdmin(false);
      setUserApiKey('');
      handleReset();
      setLoginData({
        phone: '',
        password: ''
      });
      setNewUser({
        phone: '',
        password: '',
        name: '',
        apiKey: '',
        role: 'user'
      });
    }
  };

  // 处理添加用户
  const handleAddUser = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/videos/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentUser: { role: 'admin' },
          newUser
        })
      });

      const data = await response.json();

      if (response.ok) {
        alert('用户添加成功');
        setShowAddUser(false);
        setNewUser({
          phone: '',
          password: '',
          name: '',
          apiKey: '',
          role: 'user'
        });
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Add user error:', error);
      alert('添加用户失败，请重试');
    }
  };

  // 处理视频生成
  const handleGenerate = async () => {
    const textToUse = inputMode === 'voice' ? transcript : inputText;
    
    if (!textToUse) {
      alert('请先输入或录制内容');
      return;
    }

    setIsGenerating(true);
    setProgress(0);
    setStatus('正在生成视频...');

    try {
      const result = await zhipuService.generateAndWaitForVideo(
        textToUse,
        (currentProgress, currentStatus) => {
          setProgress(currentProgress);
          setStatus(currentStatus);
        },
        userApiKey
      );

      if (result.status === 'success') {
        setVideoUrl(result.result.url);
        setStatus('视频生成成功！');
        await savePromptAndUrl(textToUse, result.result.url, userPhone);
      } else {
        setStatus(`生成失败: ${result.message}`);
      }
    } catch (error) {
      console.error('Generation error:', error);
      setStatus(`生成错误: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  // 保存 prompt 和 URL
  const savePromptAndUrl = async (prompt, videoUrl, phone) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/videos/save-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt,
          videoUrl,
		  phone
        })
      });

      if (!response.ok) {
        console.error('Failed to save prompt and URL');
      }
    } catch (error) {
      console.error('Error saving prompt and URL:', error);
    }
  };

  // 处理用户数据变化
  const handleUserDataChange = useCallback((newUserData) => {
    setNewUser(newUserData);
  }, []);

  // 处理关闭对话框
  const handleCloseDialog = useCallback(() => {
    setShowAddUser(false);
  }, []);

  return (
    <Box sx={{ bgcolor: '#0A0C10', minHeight: '100vh' }}  className='login-dialog'>
      {/* 登录对话框 */}
      {/* TextTrackCueList */}
      {
         !isLoggedIn&& (<Dialog  open={loginOpen} onClose={() => {}}>
        <DialogTitle>用户登录</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="手机号"
            type="tel"
            placeholder='请输入手机号'
            fullWidth
            value={loginData.phone}
            onChange={(e) => setLoginData(prev => ({ ...prev, phone: e.target.value }))}
          />
          <TextField
            label="密码"
            placeholder='请输入密码'
            type="password"
            autoComplete="new-password"
            fullWidth
            value={loginData.password}
            onChange={(e) => setLoginData(prev => ({ ...prev, password: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} variant="contained">
          </Button>
        </DialogActions>
      </Dialog>)}

        
      
      {/* isLoggedIn */}
      {  (
        <Box sx={{ 
          bgcolor: '#0A0C10',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          py: 4
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} className='userinfo-box'>
          
                <UserInfo />
                {isAdmin && (
                  <IconButton 
                    onClick={() => setShowAddUser(true)}
                    sx={{ 
                      color: 'cyan',
                      mr: 2
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {/* 注册按钮 */}
                {/* <Button 
                className='register-btn'
                  onClick={register}
                  variant="outlined"
                  sx={{
                    color: 'cyan',
                    borderColor: 'cyan',
                    '&:hover': {
                      borderColor: 'cyan',
                      bgcolor: 'rgba(0, 255, 255, 0.1)'
                    }
                  }}
                >
                  ➕
                </Button> */}

                <Button 
                  onClick={handleLogout}
                  variant="outlined"
                  sx={{
                    color: 'cyan',
                    borderColor: 'cyan',
                    '&:hover': {
                      borderColor: 'cyan',
                      bgcolor: 'rgba(0, 255, 255, 0.1)'
                    }
                  }}
                >
                  退出登录
                </Button>
              </Box>
          <Container maxWidth="lg">
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              align="center" 
              sx={{ 
                mb: 4,
                fontWeight: 'bold'
              }}
            >
              
              <div className='top-box'>
                <div className='top-icon'>
                </div>
                <div className='top-name'></div>
                <span className='top-time'>{time}</span>
              </div>
            </Typography>
            <Grid item >
                <Box sx={{ 
                  height: '100%',
                  minHeight: 400,
                  bgcolor: 'rgba(30, 34, 42, 0.9)',
                  borderRadius: 2,
                  overflow: 'hidden'
                }}>
                  <VideoPlayer videoUrl={videoUrl} />
                </Box>
              </Grid>
            
            <Grid container >
              <Grid item xs={24} md={12}>
                <AudioRecorder
                  isRecording={isRecording}
                  transcript={transcript}
                  inputText={inputText}
                  inputMode={inputMode}
                  onModeChange={handleModeChange}
                  onStart={startRecording}
                  onStop={stopRecording}
                  onReset={handleReset}
                  onTextChange={handleTextChange}
                  onGenerateVideo = {handleGenerate}
                />
              </Grid>
    
              
            </Grid>
    
              {/* <Box sx={{ width: '100%', mb: 2 }}>
                {isGenerating && (
                  <LinearProgress 
                    variant="determinate" 
                    value={progress}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      bgcolor: '#cfcfcf',
                      mb: 2,
                      '& .MuiLinearProgress-bar': {
                        bgcolor: 'gainsboro',
                        borderRadius: 4,
                      }
                    }}
                  />
                )}
              </Box> */}
    
            {/* <Button
              fullWidth
              variant="contained"
              onClick={handleGenerate}
              disabled={isGenerating || (!transcript && !inputText)}
              sx={{
                bgcolor: 'gainsboro',
                color: 'black',
                py: 2,
                fontSize: '1.1rem',
                fontWeight: 'bold',
                '&:hover': {
                  bgcolor: 'gainsboro',
                  opacity: 0.9
                },
                '&.Mui-disabled': {
                  bgcolor: '#cfcfcf',
                  color: 'grey.500'
                }
              }}
            >
              ⚡ Generate Video
            </Button> */}
    
            {status && (
              <Typography 
                variant="body1" 
                align="center"
                sx={{ 
                  mt: 2,
                  color: status.includes('错误') ? '#ff4d4f' : 'grey.300',
                  fontSize: '1rem'
                }}
              >
                {status}
              </Typography>
            )}
    
            {isGenerating && (
              <Typography 
                variant="body2" 
                align="center"
                sx={{ 
                  mt: 1,
                  color: 'grey.500'
                }}
              >
                {`${Math.round(progress)}% 完成`}
              </Typography>
            )}
          </Container>
        <TopButtons />
    
        </Box>
		)}
  
		{/* 添加用户对话框 */}
		{isAdmin && (
		  <AddUserDialog 
			open={showAddUser}
			onClose={handleCloseDialog}
			userData={newUser}
			onUserDataChange={handleUserDataChange}
			onAddUser={handleAddUser}
		  />
		)}
	  </Box>
	);
  }
  
  export default Home;
              