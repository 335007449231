import { useState, useCallback } from 'react';
import { bakerService } from '../services/bakerService';

export const useAudioRecorder = () => {
	const [isRecording, setIsRecording] = useState(false);
	const [audioChunks, setAudioChunks] = useState([]);
	const [transcript, setTranscript] = useState('');
	const [mediaRecorder, setMediaRecorder] = useState(null);
  
	const initRecording = useCallback(async () => {
		try {
		  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		  const recorder = new MediaRecorder(stream, {
			mimeType: 'audio/webm;codecs=opus'
		  });
		  
		  // 将 ondataavailable 和 onstop 移到这里，避免闭包问题
		  let chunks = [];
		  
		  recorder.ondataavailable = (e) => {
			console.log('Audio chunk received:', e.data.size);
			if (e.data.size > 0) {
			  chunks.push(e.data);  // 使用局部变量存储
			}
		  };
		  
		  recorder.onstop = async () => {
			console.log('Recording stopped, chunks:', chunks.length);
			if (chunks.length === 0) {
			  console.error('No audio data recorded');
			  return;
			}
			
			const audioBlob = new Blob(chunks, { type: 'audio/webm' });
			console.log('Audio blob size:', audioBlob.size);
			
			try {
			  const token = await bakerService.getAccessToken();
			  const text = await bakerService.recognizeAudio(audioBlob, token);
			  setTranscript(text);
			} catch (error) {
			  console.error('Error recognizing audio:', error);
			}
			
			chunks = []; // 清空chunks为下次录音做准备
		  };
		  
		  setMediaRecorder(recorder);
		} catch (error) {
		  console.error('Error initializing recording:', error);
		}
	  }, []); // 移除 audioChunks 依赖
  
	const startRecording = useCallback(async () => {
	  try {
		if (!mediaRecorder) {
		  await initRecording();
		}
		setAudioChunks([]); // 清空之前的录音数据
		if (mediaRecorder && mediaRecorder.state === 'inactive') {
		  mediaRecorder.start(100); // 每100ms触发一次ondataavailable事件
		  setIsRecording(true);
		}
	  } catch (error) {
		console.error('Error starting recording:', error);
	  }
	}, [mediaRecorder, initRecording]);

	const resetRecording = useCallback(() => {
		setIsRecording(false);
		setTranscript('');
		// 添加重置录音的其他逻辑
	  }, []);
  
	const stopRecording = useCallback(() => {
	  if (mediaRecorder && mediaRecorder.state === 'recording') {
		mediaRecorder.stop();
		setIsRecording(false);
	  }
	}, [mediaRecorder]);
  
	return {
	  isRecording,
	  transcript,
	  startRecording,
	  stopRecording,
	  resetRecording,
      setTranscript
	};
  };


