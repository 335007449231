import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import DeleteIcon from '@mui/icons-material/Delete';

export const AudioRecorder = ({ 
  isRecording, 
  transcript, 
  onStart, 
  onStop, 
  onGenerateVideo,
  onReset = () => {}, 
  onTextChange = () => {} 
}) => {
  const [inputMode, setInputMode] = useState('voice');
  const [textInput, setTextInput] = useState('');

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setInputMode(newMode);
      if (newMode === 'voice') {
        setTextInput('');
        onTextChange('');
      } else {
        onReset();
      }
    }
  };

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setTextInput(newText);
    onTextChange(newText);
  };

  const handleReset = () => {
    if (inputMode === 'voice') {
		console.log('reset voice');
      onReset();
	  onTextChange('');
    } else {
		console.log('reset text');
      setTextInput('');
      onTextChange('');
    }
  };

  return (
    <Box 
      sx={{ 
        height: '100%',
        bgcolor: 'rgba(30, 34, 42, 0.9)',
        borderRadius: 2,
        p: 3,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ 
        mb: 2, 
        display: 'flex', 
        alignItems: 'center',
        gap: 1
      }}>
        <MicIcon sx={{ color: 'grey.500' }} />
        <Typography variant="body1" color="grey.500">
          Microphone: {isRecording ? 'on' : 'off'}
        </Typography>
        <Box sx={{ ml: 'auto' }}>
          <ToggleButtonGroup
            value={inputMode}
            exclusive
            onChange={handleModeChange}
            size="small"
            sx={{
              '& .MuiToggleButton-root': {
                color: 'grey.500',
                borderColor: 'rgba(255, 255, 255, 0.12)',
                '&.Mui-selected': {
                  color: 'gainsboro',
                  backgroundColor: 'rgba(0, 255, 255, 0.08)'
                }
              }
            }}
          >
            <ToggleButton value="voice">
              <KeyboardVoiceIcon />
            </ToggleButton>
            <ToggleButton value="text">
              <TextFieldsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {inputMode === 'voice' ? (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <TextField 
            multiline
            rows={4}
            value={transcript}
            onChange={(e) => {
				// 允许直接编辑，无论是否在录音
				onTextChange(e.target.value);
			  }}
            placeholder="等待语音输入..."
            variant="outlined"
            fullWidth
            sx={{ 
              flex: 1,
              '& .MuiOutlinedInput-root': {
                color: 'grey.300',
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.12)'
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.2)'
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'gainsboro'
                }
              }
            }}
          />

          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            justifyContent: 'flex-start'
          }}>
            <Button
              variant="contained"
              onClick={onStart}
              sx={{ 
                bgcolor: isRecording ? 'grey.700' : 'gainsboro',
                color: isRecording ? 'grey.300' : 'black',
                '&:hover': {
                  bgcolor: isRecording ? 'grey.600' : 'gainsboro'
                },
                minWidth: 100
              }}
            >
              开始
              {/* {isRecording ? '结束' : '开始'} */}
            </Button>
            <Button
              variant="contained"
              onClick={onStop }
              sx={{ 
                bgcolor: isRecording ? 'grey.700' : 'gainsboro',
                color: isRecording ? 'grey.300' : 'black',
                '&:hover': {
                  bgcolor: isRecording ? 'grey.600' : 'gainsboro'
                },
                minWidth: 100
              }}
            >
              结束
              {/* {isRecording ? '结束' : '开始'} */}
            </Button>

            {  (
              <Button
                variant="contained"
                onClick={handleReset}
                sx={{ 
                  bgcolor: 'grey.700',
                  color: 'grey.300',
                  '&:hover': {
                    bgcolor: 'grey.600'
                  }
                }}
              >
                重置
              </Button>
            )}
            <Button
              variant="contained"
              onClick={onGenerateVideo}
              sx={{ 
                bgcolor: isRecording ? 'grey.700' : 'gainsboro',
                color: isRecording ? 'grey.300' : 'black',
                '&:hover': {
                  bgcolor: isRecording ? 'grey.600' : 'gainsboro'
                },
                minWidth: 100
              }}
            >
              生成视频
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            multiline
            rows={4}
            value={textInput}
            onChange={handleTextChange}
            placeholder="请输入提示词..."
            variant="outlined"
            fullWidth
            sx={{ 
              flex: 1,
              '& .MuiOutlinedInput-root': {
                color: 'grey.300',
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.12)'
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.2)'
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'gainsboro'
                }
              }
            }}
          />
          <div className='text-btns'>
          {textInput && (
            <Button
              variant="contained"
              onClick={handleReset}
              sx={{ 
                bgcolor: 'grey.700',
                color: 'grey.300',
                '&:hover': {
                  bgcolor: 'grey.600'
                },
                alignSelf: 'flex-start'
              }}
            >
              重置
            </Button>
            
          )}
          <Button
              variant="contained"
              onClick={onGenerateVideo}
              sx={{ 
                bgcolor: 'grey.700',
                color: 'grey.300',
                '&:hover': {
                  bgcolor: 'grey.600'
                },
                alignSelf: 'flex-start'
              }}
            >
              生成视频
            </Button>
          </div>
        </Box>
      )}
    </Box>
  );
};