import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { theme } from './styles/theme';
import { TopButtons } from './components/TopButtons';
import { History } from './pages/History';
import Home from './pages/Home';

function App() {
  const zoomValue = window.innerWidth/3840
  // const zoomValue = document.documentElement.clientHeight/2160
  console.log(window)
  // document.getElementById('root').style.zoom = zoomValue.toFixed(2);
  // document.body.style.scale = zoomValue.toFixed(2);
  document.body.style.zoom = zoomValue.toFixed(2);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{ 
          bgcolor: 'background.default',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* <TopButtons /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/history" element={<History />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;